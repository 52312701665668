<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="7">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="share-from-square" /> New Document Templates Shared
                    </CCardHeader>
                    <CCardBody>

                        <CRow>
                            <CCol lg="12" md="12" sm="12" style="padding-top: 2%;">
                                <label>Select User</label>
                                <CInput readonly placeholder="John Doe" v-model="selected_user.name"
                                    :class="inputStyle">
                                    <template #append>
                                        <CButton color="primary"
                                            @click="$refs.usersModal.users_modal = true, emitUserModal()">

                                            <font-awesome-icon icon="search" />
                                        </CButton>
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol lg="12" md="12" sm="12" >
                                <label>Categories</label>
                                <v-select label="name" v-model="selected_category" :options="category_list" :class="!category_required ? 'has-error' : 'has-success'" :disabled="selected_user.name == '' ? true : false"/>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol lg="12" style="padding-top: 2%;">
                                <label>Document Template/s</label>
                                <CDataTable :items="sorted_document_template_list" :fields="templates_field"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }"
                                    :items-per-page="5" border items-per-page-select @row-clicked="rowClicked" sorter pagination
                                    :loading="this.table_loading">
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner style="width:4rem;height:4rem;" color="info" />
                                        </center>
                                    </template>

                                    <template #checkbox-header>
                                        <center>
                                            <p-check class="p-icon p-bigger" color="success" v-model="isCheckedAll"
                                                @change="selectChange($event)">
                                                <font-awesome-icon class="icon" icon="check" />
                                            </p-check>
                                        </center>
                                    </template>

                                    <template #checkbox="{ item }">
                                        <td>
                                            <center>
                                                <p-check class="p-icon p-bigger" color="success" :value="item"
                                                    v-model="selected_templates">
                                                    <font-awesome-icon class="icon" icon="check" />
                                                </p-check>
                                                &nbsp;
                                            </center>
                                        </td>
                                    </template>

                                </CDataTable>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol col="12" class="text-right">
                                <CButton :disabled="isValid" type="submit" shape="pill" color="primary"
                                    @click="createNewDocumentShared()">
                                    <font-awesome-icon icon="save" /> Save
                                </CButton>
                            </CCol>
                        </CRow>

                    </CCardBody>
                </CCard>
            </CCol>

            <CCol lg="5">
                <CCard v-if="selected_user.name!=''">
                    <CCardHeader><font-awesome-icon icon="share-from-square" /> {{ selected_user.name +'\'s Shared Document Template/s' }}
                    </CCardHeader>
                    
                    <template>

                        <CCardBody>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                        :items="sorted_document_templates_shared"
                                        :fields="document_templates_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        items-per-page-select
                                        border
                                        sorter
                                        pagination
                                        :loading="table_loading"
                                        size="sm"
                                    >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #name="{item}">
                                        <td :title="item.name">
                                            {{item.name.length > 30 ? item.name.substring(0, 30).trim() + '...' :  item.name}}
                                        </td>
                                    </template>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </template>
                </CCard>
            </CCol>
        </CRow>
        <UsersModal ref="usersModal" @selectedUser="userSelected($event)" />
    </div>

</template>

<script>
import UsersModal from '../../modals/UsersModal';
export default {
    name : 'DocumentTemplateSharedCreate',
    components : {
        UsersModal,
    },
    data () {
        return {
            selected_category : null,
            category_list : [],
            category_required: false,
            collapse_shared : false,
            table_loading : true,
            isCheckedAll: false,
            document_template_list : [],
            selected_templates:[],
            selected_user : {
                name :'',
                head_id : ''
            },

            document_templates: [],
            document_templates_fields: [
                {key: 'name', label: 'Name'},
                {key: 'category_name', label:'Category Name'},
                // {key: 'description', label: 'Description'},
            ],
            
        }
    },
    created() {
        this.getCategoryList();
       
    },  
    computed : {
        templates_field () { 
            let custom_fields= [
                {
                    key: "checkbox", label: '', _classes: 'th-fixed-width'
                },
                { key: 'name', label: 'Template Name'},
                { key: 'category_name', label: 'Category Name'},
                // { key: 'description', label : 'Description'},
            ];
            return custom_fields;
        },

        isValid () {
           return this.selected_templates.length > 0 && this.selected_user.name != '' ? false : true;
        },

        sorted_document_template_list() {
            return this.document_template_list.sort((a,b) => a.name.localeCompare(b.name));
        },
        sorted_document_templates_shared () {
            return this.document_templates.sort((a,b) => a.name.localeCompare(b.name));
        },

        inputStyle() {
            return {
                'selected-user-required' : this.selected_user.name == '' && !this.$store.getters.getDarkMode,
                'selected-user-required-dark' : this.selected_user.name == '' && this.$store.getters.getDarkMode,
                'selected-user-filled' : this.selected_user.name != '' && !this.$store.getters.getDarkMode,
                'selected-user-filled-dark' : this.selected_user.name != '' && this.$store.getters.getDarkMode,
            }
        },
    },
    methods : {

        userSelected: function (object) {
            this.selected_user.name = object.name
            this.selected_user.head_id = object.id;
            this.getDocumentTemplateSharedList(this.paramEncoder(this.selected_user.head_id));
            if(this.selected_category!= null) {
                this.getDocumentTemplates(this.selected_category.id);
            }
        },

        emitUserModal: function () {
            this.$emit('show_users_modal');
        }, 

        getCategoryList : function () {
            this.$Progress.start();
            axios.get('/drs/document-template-category/list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {

                    for(let i = 0 ; i < response.data.data.length; i++) {
                        let option = {
                            id : response.data.data[i].id,
                            name : response.data.data[i].category,
                        };

                        this.category_list.push(option);
                    }
                    this.table_loading = false;
                    this.$Progress.finish()    
                }
            })
        }, 

        getDocumentTemplateSharedList : function(user_id) {
          this.$Progress.start()
          this.table_loading=true;
          const requestData = {
              shared_to_user_id: user_id
          };
          if(this.document_templates.length > 0){
              this.document_templates = [];
          }
          axios.post('/drs/document-template-shared/dt-list-with-parameter', requestData, {validateStatus: () => true})
          .then(response => {
              if(response.status==200){
                  this.document_templates = [];
                  for(let i=0; i<response.data.data.length; i++){
                      let dt = response.data.data[i].document_template;
                      if(dt!= null) {
                        let option = {
                            document_template_id : dt.id,
                            name : dt.name,
                            category_name : dt.category_name,
                            // description : dt.description
                        }
                        if(dt.deleted_at == null) {
                            this.document_templates.push(option);
                        }
                      }
                  }
                  this.table_loading = false
                  this.$Progress.finish()
              }
          })
        },

        getDocumentTemplates : function (category_id) {
            const requestData = {
                dt_category_id: category_id,
                user_id : this.selected_user.head_id
            };
            this.$Progress.start();
            axios.post('/drs/document-template-shared/dtc-list-with-parameter', requestData, {validateStatus: () => true})
            .then(response => {
                    if(this.document_template_list.length>0) {
                        this.document_template_list = [];
                        this.selected_templates = [];
                        
                    }
                    if(response.status == 200) {
                        this.document_template_list = [];
                        this.selected_templates = [];
                        
                        this.isCheckedAll= false;
                        for(let i = 0; i < response.data.data.length; i ++) {
                            const category = response.data.data[i].document_template_category;
                            let data = {
                                id : response.data.data[i].id,
                                name : response.data.data[i].name,
                                category_name : category.category_name,
                                // description : response.data.data[i].description
                            };

                            let item = this.document_templates.findIndex((templates) => templates.document_template_id == data.id);
                            if(item == -1) {
                                this.document_template_list.push(data);
                            }
                        }
                        this.table_loading = false;
                        this.$Progress.finish()
                    }
                  
                } 
            );
        },

        createNewDocumentShared : function () {
           let _data = {
                document_template_id : [],
                share : [
                    {
                        shared_to_user_id : this.paramEncoder(this.selected_user.head_id),
                        shared_by_user_id : this.paramEncoder(this.$store.getters['getUserDetails'].id)
                    }
                ]
           };
           
           if(this.selected_templates.length > 0) {
                for(let i = 0; i < this.selected_templates.length; i++) {
                    let dt_id = this.paramEncoder(this.selected_templates[i].id);
                    _data.document_template_id.push(dt_id);
                }
           }
           
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to share this document template(s).",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    return axios.post('/drs/document-template-shared/replace', _data, { validateStatus: () => true }).then(response => {
                        if (response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `Document Template(s) shared successfully!`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.clearData();
                            this.$Progress.finish()
                           
                        }
                    })
                }
            }).then((result) => {
                if (!result.value) {
                    this.$swal('Cancelled!', '', 'error');
                }
            });
            return;
        },

        clearData : function () {
            this.selected_user = {
            name:'', head_id: ''};
            this.document_template_list = [];
            this.selected_category = null;
            this.document_templates = [];
            this.isCheckedAll = false;
        },

        
        
        rowClicked: function(item, index, column, e) {
           if(column!='checkbox') {
            let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                if(i !== -1) {
                    if(column != 'checkbox') {
                        this.selected_templates.splice(i,1);
                    } else {
                        if(this.selected_templates.length == 1) {
                            this.isCheckedAll = false;
                            this.selected_templates = [];
                            
                        }
                    }
                    if(this.selected_templates.length < 1) {
                        this.isCheckedAll = false;
                        
                    }
                } else {
                    this.selected_templates.push(item);
                    this.collapse = true;    
                }
           }
         },

        selectedTemplates : function () {
            this.$emit('selectedTemplates', this.selected_templates);
            this.dt_modal = false
        },

        selectChange : function () {
            if(!this.isCheckedAll) {
                this.selected_templates= [];
                this.innerCollapse = false;
                this.collapse = false;
            } else {
                this.selected_templates = this.document_template_list.map(item => item);
                this.collapse = true;
            }

        },   


        
    },
    watch : {
        selected_category: function (value) {
           if(value) {
            this.getDocumentTemplates(value.id);
           }
            if(value == null) {
                this.selected_templates = [];
                this.document_template_list = [];
            }
            this.category_required = value ? true : false    
        },
    }
}
</script>